import React from "react";
import Helmet from "react-helmet";
import { ListGroup } from "react-bootstrap";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";

import Layout from "../components/Layout";

export default () => (
  <Layout>
    <SEO
      title="Renewable Energy Air Source Heat Pumps"
      description="Have issues with your Air source heat pump? Contact us to find our more information and set up a visit with one of our engineers"
      keywords="Renewable Energy Air Source Heat Pumps, Renewable Energy Air Source Heat Pumps london, Renewable Energy Air Source Heat Pumps potters bar, Renewable Energy Air Source Heat Pumps central london, Renewable Energy Air Source Heat Pumps hertfordshire"
    />

    <div class="white-segment text-left">
      <h1 class="heading-color">Air source heat pumps</h1>
      <p class="">
        A heat pump transfers thermal energy from one point to another, in the
        way that a fridge cools and evaporates the liquid to lower the internal
        temperature. This technology is used to extract thermal heat from the
        air outside.
      </p>
      <Jumbotron imgClassName="img9" />
      <h3 class="pt-2 pb-2">Main benefits of running a heat pump</h3>
      <ListGroup variant="flush">
        <ListGroup.Item>Produce 0 % emissions</ListGroup.Item>
        <ListGroup.Item>
          Run on a small amount of electricity rather than burning fuel to
          create heat
        </ListGroup.Item>
        <ListGroup.Item>
          Require less maintenance than combustion heating systems
        </ListGroup.Item>
        <ListGroup.Item>Are safer and cheaper to run</ListGroup.Item>
        <ListGroup.Item>
          Can effectively provide cooling in summer months
        </ListGroup.Item>
        <ListGroup.Item>Have a lifespan of up to 50 years</ListGroup.Item>
        <ListGroup.Item>
          Was part of the government’s renewable heat incentive scheme (RHI)
        </ListGroup.Item>
      </ListGroup>
    </div>
  </Layout>
);
